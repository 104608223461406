.custom_config_sub_txt {
    font-size: 0.95dvw;
    font-family: "Urbanist", sans-serif;
    color: #183433;
    font-weight: 500;
}

.custom_config_note_txt {
    font-size: 0.75dvw;
    font-family: "Urbanist", sans-serif;
    color: red;
    font-weight: 500;
}

.custom_border_input {
    width: "8dvw";
    border-radius: "0px";
    border: "1px solid black";
    padding: "1px";
}

.salaryTemp_tlt {
    font-size: 0.95dvw;
    font-family: "Urbanist", sans-serif;
    color: #183433;
    font-weight: 700;
}

.salaryTemp_txt {
    font-size: 0.90dvw;
    font-family: "Urbanist", sans-serif;
    color: #183433;
    font-weight: 500;
}

.custom_amount_input .ant-input,
.custom_amount_input .ant-input-affix-wrapper {
    background-color: #F5F8F7;
}