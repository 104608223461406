.subEmployeeContainer {
    width: 100%;
    height: 10.8dvw;
    margin-bottom: 10px;
}

.subEmployee_detail_txt {
    color: #183433;
    font-family: "Urbanist", sans-serif;
    font-size: 1.2dvw;
    font-weight: 600;
}

.subEmployee_BackIcon {
    width: 1.2dvw;
    height: 1.2dvw;
}

.subEmployee_details_tlt {
    font-size: 0.78dvw;
    color: #706b6b;
    font-family: "Urbanist", sans-serif;
    font-weight: 600;
}

.subEmployee_details_content {
    font-size: 0.85dvw;
    color: #183433;
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
}

.subEmployee_basic_txt {
    font-size: 0.85dvw;
    color: #183433;
    font-family: "Urbanist", sans-serif;
    margin: 0.375dvw 0dvw;
}

.with-image {
    position: relative;
    padding-left: 2.5dvw;
}

.with-image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.2dvw;
    height: 1.2dvw;
    background-size: contain;
    background-repeat: no-repeat;
}

.with-image[data-image="email"]::before {
    background-image: url(../../../assets/svg/Mail.svg);
}

.with-image[data-image="phone"]::before {
    background-image: url(../../../assets/svg/Call.svg);
}

.with-image[data-image="location"]::before {
    background-image: url(../../../assets/svg/Location.svg);
}

.subEmp_prof_subdiv {
    margin-bottom: 0.5dvw;
}

/* Subordinate setup styles */
.sub_hierarchy_container {
    width: 100%;
    height: 80dvh;
    overflow: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

.custom-drawer {
    overflow: hidden !important;
}

.custom-drawer .ant-drawer-title {
    font-size: 1.2dvw;
    color: #183433;
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
}

.custom-drawer .ant-drawer-body {
    padding: 10px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.cardColorTxt {
    font-size: 0.95dvw;
    color: #183433;
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
}

.subEmployee_moreIcon {
    width: 0.85dvw;
    height: 0.85dvw;
}

.subordinate_cardTxt {
    font-size: 0.85dvw;
    color: white;
    text-align: left;
    padding-Left: 5px;
    font-family: "Urbanist", sans-serif;
}

.subordinate_wo_add_icon {
    width: 1.5dvw;
    height: 1.5dvw;
    margin-right: 3px;
}