/* Example Styles for React Tags*/

  .ReactTags__tags {
    position: relative;
  }
  
  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 35px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    /* width: 100%; */
    min-width: 250px;
    border-radius: 4px;
    padding: 5px;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #e4e4e4;
    background: #e4e4e4;
    color: #000;
    font-size: 14px;
    display: inline-block;
    padding: 5px;
    margin: 5px 5px 0 0;
    border-radius: 4px;
  }
  .ReactTags__selected {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  button.ReactTags__remove {
    background-color: #afafaf; /* Set the background color of the button */
    border: none; /* Remove the button border */
    color: #fff; /* Set the text color */
    border-radius: 60%; /* Make the button round by setting the border-radius to 50% */
    padding: 0px 2px; /*Adjust padding as needed */
    cursor: pointer; /* Show a pointer cursor on hover */
    outline: none; /* Remove the default focus outline */
    margin-left: 3px;
    font-size: 10px;
    text-decoration: none;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }